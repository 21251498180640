import * as React from 'react';
import { useState, useEffect } from 'react';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';

//import styles from './FourOhFour.module.scss';

import * as globalStyles from '../globals.module.scss';

export interface IFourOhFourProps{

}

const FourOhFour = (props: IFourOhFourProps) => {
    return (
        <div className={ globalStyles.bodyGrid }>
            <Header />
            <section className={ globalStyles.sectionGrid }>
                <span>Uh oh, we couldn't find a page to match this URL.</span>
            </section>
            <Footer />
        </div>
    );
}

export default FourOhFour;